/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'train-lightrail-front-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M6.5 0a.5.5 0 000 1h1v1.011c-1.525.064-3.346.394-4.588.655C1.775 2.904 1 3.915 1 5.055V13.5A2.5 2.5 0 003.5 16h9a2.5 2.5 0 002.5-2.5V5.055c0-1.14-.775-2.15-1.912-2.39-1.242-.26-3.063-.59-4.588-.654V1h1a.5.5 0 000-1zM8 4c1.136 0 2.645.2 3.604.346.825.126 1.356.9 1.244 1.697q-.034.24-.07.522C12.643 7.596 12.5 8.949 12.5 10c0 .428.024.933.062 1.464.066.93.174 1.92.266 2.682.042.34.08.634.109.854h-1.01a63 63 0 01-.327-3H9.354c-.36 0-.704-.143-.958-.396a.35.35 0 00-.25-.104h-.292a.35.35 0 00-.25.104 1.35 1.35 0 01-.958.396H4.4a63 63 0 01-.328 3H3.064c.029-.22.067-.514.108-.854.092-.761.2-1.752.266-2.682.038-.531.062-1.036.062-1.464 0-1.051-.143-2.404-.278-3.435l-.07-.522c-.112-.798.42-1.571 1.244-1.697C5.356 4.199 6.864 4 8 4m-1.354 7H4.47c.019-.353.03-.692.03-1 0-.927-.104-2.051-.216-3h7.432c-.112.949-.216 2.073-.216 3 0 .308.011.647.03 1H9.354a.35.35 0 01-.25-.104 1.35 1.35 0 00-.958-.396h-.292c-.36 0-.704.143-.958.396a.35.35 0 01-.25.104m5.199-5h-7.69l-.013-.096a.497.497 0 01.405-.57C5.505 5.188 6.947 5 8 5s2.495.188 3.453.334a.497.497 0 01.405.57zM6 13.5a.5.5 0 11-1 0 .5.5 0 011 0m0 0a.5.5 0 111 0 .5.5 0 01-1 0m4 0a.5.5 0 11-1 0 .5.5 0 011 0m0 0a.5.5 0 111 0 .5.5 0 01-1 0"/>',
    },
});
